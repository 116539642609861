<template>
  <div class="interact-index-container">
    <div class="interact-index-container-bread">
      <router-link :to="{ name: 'culture_homepage' }">首页</router-link>
      / <span>互动平台</span>
    </div>
    <div class="interact-index-container-QRCode">
      <img
        src="https://image.bookgo.com.cn/webculture/jm/interact/interact-platform.png"
        alt=""
      />
      <div class="wxCode">
        <img :src="detailSrc" alt="" style="width:100%;height:100%" />
      </div>
    </div>
    <!-- 常见问题 -->
    <div class="interact-index-container-FAQ">
      <div class="interact-index-container-FAQ-title">
        <img
          src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_left.png"
          alt=""
        />
        <img
          src="https://image.bookgo.com.cn/webculture/jm/interact/interact-problem.png"
          alt=""
        />
        <img
          src="https://image.bookgo.com.cn/webculture/jm/homepage/title_icon_right.png"
          alt=""
        />
      </div>
      <div class="interact-index-container-FAQ-content">
        <div class="interact-index-container-FAQ-content-headPic">
          <img
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="interact-index-container-FAQ-content-footerPic">
          <img
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="interact-index-container-FAQ-content-data">
          共{{ page }}页 丨 {{ total }}条数据
        </div>
        <div class="interact-index-container-FAQ-content-list">
          <ul>
            <li
              v-for="(item, i) in commentList"
              :key="i"
              @mouseover="current = i"
              @mouseleave="current = -1"
            >
              <div
                class="interact-index-container-FAQ-content-list-title"
                :class="{ isActive: current == i }"
              >
                <img
                  :src="
                    current == i
                      ? 'https://image.bookgo.com.cn/webculture/jm/interact/interact-problem-active.png'
                      : 'https://image.bookgo.com.cn/webculture/jm/interact/interact-problem-inactive.png'
                  "
                  alt=""
                />
                {{ item.title }}
              </div>
              <div class="interact-index-container-FAQ-content-list-content">
                <!-- <h3>{{ item.update_time }}</h3> -->
                <p>
                  {{ item.answer }}
                </p>
                <h3 style="text-align:right">{{ item.update_time }}</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 分页组件 -->
    <Pagination
      :total="total"
      @pagination="getCommentList"
      :page.sync="page"
      :limit.sync="limit"
      v-if="commentList && commentList.length !== 0"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import { msgList } from "@/api/interact/interact";
import { genQRCode } from "@/api/common";
import moment from "moment";
export default {
  components: { Pagination },
  data() {
    return {
      total: 0,
      page: 1,
      limit: 6,
      current: -1,
      aid: process.env.VUE_APP_AID,
      commentList: [],
      detailSrc: "",
    };
  },
  created() {
    this.getCommentList();
    this.handleServe();
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    getCommentList() {
      msgList({
        aid: this.aid,
        section_id: 1,
        page: this.page,
        page_size: this.limit,
      }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.commentList = res.data.list;
          this.total = res.data.list.length;
          res.data.list.forEach((item) => {
            item.update_time = moment(item.update_time * 1000).format(
              "YYYY-MM-DD hh:mm"
            );
          });
        }
      });
    },
    handleServe() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/feedback/leaveFb/leaveFb?aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid: process.env.VUE_APP_AID,
      };
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/interact.scss";
</style>
